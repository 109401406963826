<template>
  <div class="bill-page">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="getByType('title')">
      <template v-slot:toolbar>
        <b-row>
          <div>
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
            >
              <template slot="button-content">
                <i
                  style="font-size: 1rem"
                  class="fas fa-cog"
                ></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="showModalExcel">
                <span>
                  <i
                    style="font-size: 1rem"
                    class="far fa-file-excel"
                  ></i>
                  &nbsp; Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-if="checkPermission('BILL_INSERT')"
            class="ml-3"
          >
            <router-link :to="{
                name: getByType('router'),
              }">
              <b-button
                variant="primary"
                size="sm"
                class="font-weight-bolder"
              >
                <i
                  style="font-size: 1rem"
                  class="flaticon2-add-1"
                ></i> Tạo mới
              </b-button>
            </router-link>
          </div>
        </b-row>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-form-select
              size="sm"
              class="select-style"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Doanh nghiệp --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filteredStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
            />
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập tên sản phẩm"
              v-model="searchProductName"
              size="sm"
            ></b-input>
          </b-col>
          <b-col>
            <div class="search-col">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchEmployee"
              :suggestions="filteredOptionsEmployee"
              :placeholder="'Nhân viên bán hàng'"
              :limit="10"
              @select="onSelectedEmployee"
              @change="onInputChangeEmployee"
            >
              <template #custom="{ suggestion }">
                <div>
                  <span>{{ suggestion.item.fullName }}</span>
                </div>
              </template>
            </Autosuggest>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              placeholder="Nhập ID đơn hàng"
              v-model="searchOrderId"
              size="sm"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập tên KH/Sdt"
              v-model="searchNameOrPhone"
              size="sm"
            ></b-input>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="value"
              :options="filterdOptions"
              :placeholder="'đặc điểm'"
              :matchKey="['name']"
              :searchable="true"
            />
          </b-col>
          <b-col>
            <b-input
              placeholder="Mã coupon"
              v-model="searchCoupon"
              size="sm"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập mã IMEI"
              v-model="searchIMEI"
              size="sm"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              placeholder="Nhập ID hóa đơn"
              v-model="searchId"
              size="sm"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập ghi chú"
              v-model="searchNote"
              size="sm"
            ></b-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              class="filter-btn"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc
            </b-button>
          </b-col>
        </b-row>
        <table class="table table-bordered-list table-hover col-md-12">
          <thead>
            <tr>
              <th class="text-center tdCode">ID</th>
              <th class="text-center tdCustomer">Khách hàng</th>
              <th class="text-center tdProductName">Sản phẩm</th>
              <th class="text-center tdProductPrice">Giá</th>
              <th class="text-center tdQuantity">SL</th>
              <th class="text-center tdDiscountItem">C.Khấu</th>
              <th class="text-center tdTotalAmount">Tổng tiền</th>
              <th class="text-center tdTotalPayment">Thanh toán</th>
              <th class="text-center tdNote">Ghi chú</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(bill, i) in bills">
              <tr
                :key="i"
                @mouseover="hoverRow"
                @mouseleave="outRow"
                :data="`row_${bill.billNumber}`"
              >
                <td
                  colspan="7"
                  style="
                    padding: 5px !important;
                    border-bottom: none !important;
                  "
                >
                  <div class="d-flex">
                    <div>
                      <b> {{ bill.storeName }}</b>
                      <span
                        class="ml-1"
                        v-if="bill.companyName"
                        style="font-size: 10px"
                      >( {{ bill.companyName }} )</span>
                    </div>
                  </div>

                  <div
                    class="d-flex"
                    style="font-size: 0.87rem"
                  >
                    <span class="mr-4">Ngày tạo:
                      <span class="text-primary">
                        {{ bill.createdAt }}</span></span>
                    <span class="mr-4">
                      <span class="mr-1">TVBH:</span>
                      <span v-if="bill.saleName">
                        {{ bill.saleName }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Chưa được gán </span>
                    </span>
                    <span class="mr-4">
                      <span class="mr-1">NVTN:</span>
                      <span v-if="bill.cashierName">
                        {{ bill.cashierName }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Chưa được gán </span>
                    </span>
                    <span class="mr-4">
                      <span class="mr-1">NVKT:</span>
                      <span v-if="bill.technicalName">
                        {{ bill.technicalName }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Chưa được gán </span>
                    </span>
                  </div>
                </td>
                <td
                  style="border-bottom: none !important"
                  colspan="2"
                >
                  <b-row>
                    <b-col cols="10">
                      <div
                        class="d-flex"
                        style="font-size: 0.87rem"
                      >
                        <span v-if="bill.couponCode">
                          Mã coupon: {{ bill.couponCode }}
                        </span>
                      </div>
                    </b-col>
                    <b-col
                      cols="2"
                      class="text-right"
                    >
                      <i
                        class="fa fa-arrow-circle-o-down"
                        aria-hidden="true"
                      ></i>
                    </b-col>
                  </b-row>
                </td>
                <td
                  style="border-bottom: none !important"
                  class="text-center"
                >
                  <i
                    class="fa fa-download text-primary cursor-pointer"
                    aria-hidden="true"
                    @click="openFileModal(bill)"
                  ></i>
                </td>
              </tr>
              <tr
                :key="i + 10"
                v-if="bill.showDetail"
              >
                <td
                  colspan="10"
                  style="border-bottom: none !important"
                >
                  <b-col cols="3"></b-col>
                </td>
              </tr>
              <template v-for="(billItem, j) in bill.listProduct">
                <tr
                  v-if="j < limitListProduct"
                  :key="i + j + billItem.id"
                  @mouseover="hoverBill"
                  @mouseleave="outBill"
                  :data="`row_${bill.billNumber}`"
                >
                  <td
                    :rowspan="
                      bill.listProduct.length > limitListProduct
                        ? limitListProduct
                        : bill.listProduct.length
                    "
                    v-if="j === 0 || bill.show"
                    class="tdTextAlign tdCode"
                  >
                    <div class="text-center mb-2">
                      <span
                        title="Từ app mobile"
                        v-b-tooltip
                        v-if="bill.deviceType === 2"
                      >
                        <i class="fas fa-mobile-alt text-dark ml-1"></i>
                      </span>
                      <span
                        title="Từ web"
                        v-b-tooltip
                        v-else-if="bill.deviceType === 1"
                      >
                        <i class="fas fa-globe-asia text-dark"></i>
                      </span>
                      <span
                        title="Chưa xác định"
                        v-b-tooltip
                        v-else
                      >
                        <i
                          class="fa fa-stop-circle text-danger"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <div
                      class="orderCode"
                      style="cursor: pointer; color: #3699ff"
                    >
                      <a
                        class="font-weight-bold"
                        v-bind:href="getByType('router-link-id', bill)"
                        target="_self"
                      >
                        {{ bill.billNumber }}
                      </a>
                    </div>
                    <div
                      v-if="bill.type === 4"
                      class="text-center"
                    >
                      <span class="text-success"><i
                          class="fas fa-shipping-fast"
                          title="Hóa đơn giao hàng từ đơn hàng"
                        ></i></span>
                      <div
                        class="orderCode"
                        @click="linkToOrder(bill.orderId)"
                        style="cursor: pointer"
                      >
                        <span
                          style="color: darkgoldenrod"
                          v-text="bill.orderId"
                        ></span>
                      </div>
                    </div>
                    <div
                      v-if="bill.type === 1 && bill.orderId"
                      class="text-center"
                    >
                      <span class="text-warning"><i
                          class="fas fa-shopping-basket"
                          title="Hóa đơn từ đơn hàng"
                        ></i></span>
                      <div
                        class="orderCode"
                        @click="linkToOrder(bill.orderId)"
                        style="cursor: pointer"
                      >
                        <span
                          style="color: darkgoldenrod"
                          v-text="bill.orderId"
                        ></span>
                      </div>
                    </div>
                  </td>
                  <td
                    :rowspan="
                      bill.listProduct.length > limitListProduct
                        ? limitListProduct
                        : bill.listProduct.length
                    "
                    v-if="j === 0 || bill.show"
                    class="tdTextAlign tdCustomer"
                  >
                    <p
                      class="orderCode"
                      @click="editUser(bill)"
                      style="
                        margin-bottom: 0;
                        cursor: pointer;
                        font-weight: 550;
                      "
                    >
                      {{ bill.customerName }}
                    </p>
                    <p
                      class="orderCode"
                      @click="editUser(bill)"
                      style="margin-bottom: 0; cursor: pointer"
                    >
                      {{ bill.customerMobile }}
                    </p>
                    <p
                      v-if="bill.imei"
                      class="orderCode text-primary"
                      style="margin-bottom: 0; cursor: pointer"
                    >
                      imei:{{ bill.imei }}
                    </p>
                  </td>
                  <td class="tdTextAlign tdProductName">
                    <div v-if="billItem.typeProduct !== 3">
                      <div
                        v-if="billItem.productType === 2"
                        class="font-size-gift"
                      >
                        <i
                          style="color: #2e7d32"
                          class="fas fa-gift ml-4 mr-1"
                          title="Quà tặng"
                        ></i>
                        {{ billItem.productName }}
                        <MerchantLabel :merchantId="billItem.merchantId" />
                      </div>
                      <div
                        v-else-if="billItem.productType === 3"
                        class="font-size-attach"
                      >
                        <i
                          style="color: #37474f"
                          class="fas fa-tags ml-4 mr-1"
                          title="Bán kèm"
                        ></i>
                        {{ billItem.productName }}
                        <MerchantLabel :merchantId="billItem.merchantId" />
                      </div>
                      <div v-else>
                        {{ billItem.productName }}
                        <MerchantLabel :merchantId="billItem.merchantId" />
                      </div>
                      <p
                        class="text-imei-no"
                        v-if="billItem.imeiCode.trim() !== ''"
                      >
                        IMEI: {{ billItem.imeiCode }}
                      </p>
                    </div>
                    <div v-else>
                      <div
                        class="table-responsive"
                        v-show="
                          billItem.comboItems !== undefined &&
                            billItem.comboItems.length > 0
                        "
                      >
                        <table>
                          <tr>
                            <td style="border: none; padding-left: 0">
                              <p class="m-0">
                                {{ billItem.productName }}
                                <MerchantLabel :merchantId="billItem.merchantId" />
                              </p>
                            </td>
                          </tr>
                          <tr
                            v-for="(items, i) in billItem.comboItems"
                            :key="i"
                            style="border-top: 1px solid gray"
                          >
                            <td style="
                                border: none;
                                width: -webkit-fill-available;
                              ">
                              <i
                                style="color: rgb(24, 28, 50)"
                                class="fas fa-cube"
                              ></i>
                              {{ items.productName }}
                              <MerchantLabel :merchantId="billItem.merchantId" />
                              <i style="font-size: unset; color: unset">({{ convertPrice(items.sellingPrice) }})</i>
                            </td>
                            <td style="border: none; font-size: 13px">
                              {{ items.quantity }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                  <td class="tdTextAlignPrice tdProductPrice">
                    {{ convertPrice(billItem.productPrice) }}
                  </td>
                  <td class="tdTextAlignPrice tdQuantity">
                    {{ billItem.quantity }}
                  </td>
                  <td class="tdTextAlignPrice tdDiscountItem">
                    <p v-if="billItem.discountType === 1">
                      {{ convertPrice(billItem.discount) }}
                    </p>
                    <p v-if="
                        billItem.discountType === 2 && billItem.discount > 0
                      ">
                      {{ convertPrice(billItem.discount) }}%
                      <br />
                      <span style="color: #ffa800; font-style: italic">
                        {{ convertPrice(billItem.discountItemAmount) }}</span>
                    </p>
                    <p v-if="
                        billItem.discountType === 2 && billItem.discount === 0
                      ">
                      {{ convertPrice(billItem.discount) }}
                    </p>
                  </td>
                  <td
                    class="tdTextAlignPrice tdTotalAmount"
                    :rowspan="
                      bill.listProduct.length > limitListProduct
                        ? limitListProduct
                        : bill.listProduct.length
                    "
                    v-if="j === 0 || bill.show"
                  >
                    <p
                      v-show="bill.shippingFee > 0"
                      class="border-0"
                    >
                      <i
                        v-b-tooltip.hover
                        title="Phí ship"
                        class="fas fa-shipping-fast icon-color"
                      ></i>
                      : {{ convertPrice(bill.shippingFee) }}
                    </p>
                    <p
                      v-show="
                        bill.discountAmount > 0 ||
                          bill.discountAmount.includes('%') ||
                          bill.discountAmount.includes('.')
                      "
                      class="border-0 text-warning"
                    >
                      <i
                        v-b-tooltip.hover
                        title="Chiết khấu tổng"
                        class="fa fa-arrow-down icon-color"
                      ></i>
                      : {{ bill.discountAmount }}
                    </p>
                    <b style="color: #3c763d">
                      {{ convertPrice(bill.totalAmount) }}</b>
                  </td>
                  <td
                    class="tdTextAlignPrice tdTotalPayment"
                    :rowspan="
                      bill.listProduct.length > limitListProduct
                        ? limitListProduct
                        : bill.listProduct.length
                    "
                    v-if="j === 0 || bill.show"
                  >
                    <p
                      v-show="bill.pointToMoney > 0"
                      class="border-0"
                    >
                      <i
                        v-b-tooltip.hover
                        title="Tiêu điểm"
                        class="fas fa-adjust icon-color"
                      ></i>
                      : {{ convertPrice(bill.pointToMoney) }}
                    </p>
                    <!-- <p v-show="bill.transferAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      :title="bill.titleTransfer"
                      class="fa fa-university icon-color"
                    ></i>
                    : {{ convertPrice(bill.transferAmount) }}
                  </p>
                  <p v-show="bill.creditAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      :title="bill.titleCredit"
                      class="fa fa-credit-card icon-color"
                    ></i>
                    : {{ convertPrice(bill.creditAmount) }}
                  </p>
                  <p v-show="bill.cashAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      :title="bill.titleCash"
                      class="fas fa-money-bill-alt icon-color"
                    ></i>
                    : {{ convertPrice(bill.cashAmount) }}
                  </p>
                   <p v-show="bill.installlAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      :title="bill.titleCash"
                      class="fa fa-cube icon-color"
                    ></i>
                    : {{ convertPrice(bill.installlAmount) }}
                  </p> -->
                    <template v-if="bill.payments && bill.payments.length">
                      <div
                        v-for="(payment, index) in bill.payments"
                        :key="index"
                        class="mb-2"
                      >
                        <span class="mr-2">
                          <i
                            v-b-tooltip.hover
                            :title="payment.paymentName"
                            class="icon-color text-dark mr-1"
                            :class="
                              payment.paymentType === 1
                                ? 'fas fa-money-bill-alt'
                                : payment.paymentType === 2
                                ? 'fa fa-university'
                                : payment.paymentType === 3
                                ? 'fa fa-credit-card'
                                : payment.paymentType === 4
                                ? 'fa fa-cube'
                                : ''
                            "
                          ></i>:
                        </span>
                        <span style="font-weight: 700">{{
                          convertPrice(payment.paymentAmount)
                        }}</span>
                      </div>
                    </template>
                    <p
                      v-if="bill.extraAmount > 0"
                      class="d-block text-primary"
                    >
                      Tiền thừa:
                      <span class="d-block">{{
                        convertPrice(bill.extraAmount)
                      }}</span>
                    </p>
                    <p
                      v-if="bill.extraAmount < 0"
                      class="d-block"
                      style="color: #e65b5b"
                    >
                      Còn thiếu:
                      <span class="d-block">
                        {{ convertPrice(bill.extraAmount * -1) }}</span>
                    </p>
                    <p v-show="bill.leftAmount > 0">
                      Công nợ:
                      <span class="d-block">
                        {{ convertPrice(bill.leftAmount) }}</span>
                    </p>
                  </td>
                  <td
                    class="tdTextAlign tdNote wrapword"
                    :rowspan="
                      bill.listProduct.length > limitListProduct
                        ? limitListProduct
                        : bill.listProduct.length
                    "
                    v-if="j === 0 || bill.show"
                    @mouseover="hoverPrice = true"
                    @mouseleave="hoverPrice = false"
                  >
                    <p
                      v-if="bill.customerNote"
                      :title="bill.customerNote"
                      v-b-tooltip.hover
                    >
                      {{ truncateText(bill.customerNote) }}
                    </p>
                    <p
                      v-if="bill.customerNote && bill.warrantyNote"
                      class="dashed"
                    ></p>
                    <p
                      v-if="bill.warrantyNote"
                      :title="bill.warrantyNote"
                      v-b-tooltip.hover
                    >
                      {{ truncateText(bill.warrantyNote) }}
                    </p>
                    <span @click="handleShowNoteModel(bill)">
                      <i class="fas fa-edit cursor-pointer"></i>
                    </span>
                  </td>
                  <td
                    :rowspan="
                      bill.listProduct.length > limitListProduct
                        ? limitListProduct
                        : bill.listProduct.length
                    "
                    v-if="j === 0 || bill.show"
                    class="tdTextAlign"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        size="sm"
                        id="dropdown-left"
                        no-caret
                        right
                      >
                        <template slot="button-content">
                          <i class="flaticon2-settings function-btn"></i>
                        </template>
                        <b-dropdown-item @click="printBill(bill)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="flaticon2-printer"
                            ></i>
                            &nbsp; In hóa đơn
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="returnItem(bill)"
                          v-if="bill.isShowReturnBill"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="flaticon2-reply-1"
                            ></i>
                            &nbsp; Trả hàng
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="rePostItem(bill)"
                          v-if="isShowByType('recreate')"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fas fa-redo"
                            ></i>
                            &nbsp; Tạo lại
                          </span>
                        </b-dropdown-item>
                        <!-- create warranty bill button -->
                        <b-dropdown-item @click="createWarrantyBill(bill)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fas fa-toolbox"
                            ></i>
                            &nbsp; Tạo hóa đơn bảo hành
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showPopupVatInvoice(bill, 'add')"
                          v-if="
                            isShowByType('invoice-vat') &&
                              checkPermission('VAT_INVOICE_INSERT')
                          "
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fas fa-exchange-alt"
                            ></i>
                            &nbsp; Tạo hóa đơn đỏ
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="editItem(bill)"
                          v-if="checkViewOnly() || bill.companyId !== 3"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="flaticon-eye"
                            ></i>
                            &nbsp; Chi tiết hóa đơn
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="editItem(bill)"
                          v-if="!checkViewOnly() && bill.companyId === 3"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="flaticon2-pen"
                            ></i>
                            &nbsp; Chỉnh sửa
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="showDeleteAlert(bill)"
                          v-if="
                            checkPermission('BILL_DELETE') &&
                              bill.companyId === 3
                          "
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem; color: #d33"
                              class="flaticon2-rubbish-bin-delete-button"
                            ></i>
                            &nbsp; Xóa
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
            <tr v-if="!onLoading">
              <td
                :colspan="4"
                class="tdTextAlignPrice text-weight"
              >Tổng</td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalQuantity) }}
              </td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalAmount) }}
              </td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalPayment) }}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <vcl-table
          v-if="onLoading"
          :speed="5"
          :animate="true"
          :columns="11"
        ></vcl-table>

        <!-- End of Table session -->

        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho hóa đơn ' + idBill"
          >
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Ghi chú bán hàng</label>
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Ghi chú bảo hành</label>
                <b-form-textarea
                  v-model="warrantyNote"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  class="edit-note-btn"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                >Lưu
                </b-button>
                <b-button
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                >Hủy
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số hóa đơn:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              v-show="totalPages >= 2"
              class="customPagination"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchListBill"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
    <template>
      <BillExcel
        :param="apiParams"
        :mode="mode"
      />
    </template>
    <template>
      <b-modal
        ref="file-modal"
        size="lg"
        hide-footer
        title="Chỉnh sửa tệp"
        id="file-modal"
      >
        <b-row>
          <b-col cols="12">
            <UploadFile
              :id="entityUploadId"
              :entity="'bills'"
            />
          </b-col>
        </b-row>
      </b-modal>
    </template>
    <template>
      <BillVatInvoiceModal />
    </template>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
import jQuery from 'jquery';
import { cloneDeep, map, find, assign, flatten, sumBy } from 'lodash';
import moment from 'moment';
import { TIME_TRIGGER, LIMIT_LIST_PRODUCT } from '@/utils/constants';
import localData from '@/utils/saveDataToLocal';
import { removeAccents, getValuesEnum } from '@/utils/common';
import { BILL_TYPE } from '@/utils/enum';

import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { EventBus } from '@/core/services/event-bus';
import ApiService from '@/core/services/api.service';

import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';

import BillExcel from '@/view/components/bills/BillExcel.vue';
import BillVatInvoiceModal from '@/view/components/bills/BillVatInvoiceModal.vue';
import UploadFile from '@/view/modules/upload/Upload';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import MerchantLabel from '@/view/components/MerchantLabel.vue';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  props: {
    billType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      listStores: [{ value: '', text: 'Chọn cửa hàng' }],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      hoverPrice: false,
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      bills: [],
      onLoading: true,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      currentPage: 1,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchProductName: '',
      searchId: '',
      searchNameOrPhone: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      idBill: '',
      showEditPriceModal: false,
      note: '',
      filteredOptions: [],
      optionsStore: [],
      selectedStore: null,
      searchStock: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      selectedEmployee: null,
      searchEmployee: '',
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 2,
          name: 'Đã khóa',
        },
      ],
      selectedStatus: 1,
      value: [],
      options: [
        {
          code: 1,
          name: 'Có tiền chiết khấu',
          checked: false,
        },
        {
          code: 2,
          name: 'Không có chiết khấu',
          checked: false,
        },
        {
          code: 3,
          name: 'Có tiền chuyển khoản',
          checked: false,
        },
        {
          code: 4,
          name: 'Có thanh toán quẹt thẻ',
          checked: false,
        },
        {
          code: 5,
          name: 'Có thanh toán trả góp',
          checked: false,
        },
        {
          code: 6,
          name: 'Có dùng tiền tích điểm',
          checked: false,
        },
        {
          code: 7,
          name: 'Có công nợ',
          checked: false,
        },
        {
          code: 8,
          name: 'Còn công nợ',
          checked: false,
        },
        {
          code: 9,
          name: 'Hết công nợ',
          checked: false,
        },
        {
          code: 10,
          name: 'Có tiền mặt',
          checked: false,
        },
        {
          code: 14,
          name: 'Còn thiếu',
          checked: false,
        },
        {
          code: 18,
          name: 'Còn thừa',
          checked: false,
        },
        {
          code: 15,
          name: 'Chưa thanh toán',
          checked: false,
        },
        {
          code: 11,
          name: 'Hóa đơn từ đơn hàng',
          checked: false,
        },
        {
          code: 13,
          name: 'Có cọc từ đơn hàng',
          checked: false,
        },
        {
          code: 12,
          name: 'Có quà tặng',
          checked: false,
        },
        {
          code: 17,
          name: 'Có sử dụng coupon',
          checked: false,
        },
        {
          code: 19,
          name: 'Có hoá đơn đỏ',
          checked: false,
        },
      ],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      dpForm: {
        startDate: '',
        endDate: '',
      },
      warrantyNote: '',
      searchIMEI: '',
      searchOrderId: '',
      searchNote: '',
      searchCoupon: '',
      apiParams: {
        fromDate: null,
        toDate: null,
      },
      mode: null,
      hoverTable: {
        dataId: null,
      },
      showDetail: false,
      entityUploadId: null,
      filteredStore: [],
      filterdOptions: [],
      titleBill: '',
      limitListProduct: LIMIT_LIST_PRODUCT,
      modalBillVatId: '',
      isAllowCreateWarrantyBill: true,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest,
    BillExcel,
    UploadFile,
    MerchantLabel,
    BillVatInvoiceModal,
    BDropdownCustom,
  },
  async created() {
    // open để triển khai cho tất cả cửa hàng
    // this.isAllowCreateWarrantyBill = await this.checkFeatureByUser();
    this.getFillter();
    this.fetchCompany();
    this.getListEmployee();
    await this.fetchStoreByUser();
    this.fetchListBill();
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn', route: 'bills' },
      { title: 'Danh sách hóa đơn' },
    ]);
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  methods: {
    checkFeatureByUser: async function () {
      let result = true;
      await ApiService.setHeader();
      await ApiService.query('bills/check-warranty-feature')
        .then((response) => {
          const { status } = response.data;
          if (status !== 1) {
            this.isAllowCreateWarrantyBill = false;
          }
        })
        .catch(() => {
          result = false;
        });

      return result;
    },
    isShowByType(type) {
      switch (type) {
        case 'recreate': {
          return this.billType === BILL_TYPE.RETAIL_INVOICE;
        }
        case 'create-warranty': {
          return this.billType === BILL_TYPE.RETAIL_INVOICE;
        }
        case 'invoice-vat': {
          return [
            BILL_TYPE.RETAIL_INVOICE,
            BILL_TYPE.WHOLESALE_INVOICE,
          ].includes(this.billType);
        }
      }
    },
    openFileModal(item) {
      this.entityUploadId = item.billNumber;
      this.$refs['file-modal'].show();
    },
    truncateText(text) {
      const MAX_STRING = 200;
      if (!text || !text.length) {
        return '';
      }
      return text.length < MAX_STRING
        ? text
        : text.substring(0, MAX_STRING) + '...';
    },
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        const isShown = jQuery('.modal').hasClass('show');
        if (!isShown) {
          this.onFilter();
        }
      }
    },
    hoverRow: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      if (this.hoverTable.dataId !== dataId) {
        const rows = jQuery(`[data=${dataId}]`);
        rows.addClass('tr-rowspan-hover');
        this.hoverTable.dataId = dataId;
      }
    },
    outRow: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
      this.hoverTable.dataId = null;
    },
    printBill: async function (item) {
      let routeData = this.$router.resolve({
        name: 'print-bill',
        query: { id: item.billNumber },
      });
      window.open(routeData.href, '_self');
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`,
      ).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        this.filteredStore = cloneDeep(this.optionsStore);
      });
    },
    convertPrice: function (number = 0) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    editItem: function (item) {
      const { href } = this.$router.resolve({
        name: this.getByType('router-update'),
        query: { id: item.billNumber },
      });
      window.open(href, '_self');
    },
    returnItem: function (item) {
      this.$router.push({
        name: 'add-return-bill',
        query: { id: item.billNumber },
      });
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.idBill = item.billNumber;
      this.note = item.customerNote;
      this.warrantyNote = item.warrantyNote;
    },
    updateNote() {
      let data = {
        id: this.idBill,
        customerNote: this.note,
        warrantyNote: this.warrantyNote,
      };
      ApiService.put('bills/update-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchListBill();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    searchBill() {
      this.isSearching = true;
      this.fetchListBill();
    },
    fetchListBill2: async function () {
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      this.onLoading = true;
      let listCriteria = [];
      this.options.forEach((element) => {
        if (element.checked) {
          listCriteria.push(element.code);
        }
      });
      this.filterdOptions = cloneDeep(this.options);
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }
      let param = {
        page: this.page,
        limit: 10,
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: 1,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        listCriteria: listCriteria,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
        searchCoupon: this.searchCoupon ? this.searchCoupon.trim() : '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('bills', paramQuery)
        .then(({ data }) => {
          this.bills = [];
          this.totalPages = data.data.totalPage;
          this.totalItems = data.data.totalRow;
          this.totalQuantity = 0;
          this.totalAmount = 0;
          this.totalPayment = 0;
          data.data.data.forEach((data) => {
            for (let index = 0; index < data.listProduct.length; index++) {
              const element = data.listProduct[index];
              let show = false;
              if (index === 0) {
                show = true;
              }
              let discountAmount = '';
              if (data.discountType === 2) {
                discountAmount = data.discountAmount + '%';
              } else if (data.discountType === 1) {
                discountAmount = this.convertPrice(data.discountAmount);
              }
              let item = {
                id: element.id,
                billNumber: data.billNumber,
                customerName: data.customerName,
                customerPhone: data.customerMobile,
                saleName: data.saleName,
                cashierName: data.cashierName,
                storeName: data.storeName,
                totalAmount: data.totalAmount,
                paymentAmount: data.paymentAmount,
                creditAmount: data.creditAmount,
                cashAmount: data.cashAmount,
                transferAmount: data.transferAmount,
                leftAmount: data.leftAmount,
                extraAmount: data.extraAmount,
                discountAmount: discountAmount,
                note: data.customerNote,
                warrantyNote: data.warrantyNote,
                productItem: element,
                date: data.createdAt,
                show: show,
                groupSize: data.listProduct.length,
                productType: element.productType,
                type: data.type,
                orderId: data.orderId,
                installlAmount: data.installlAmount,
                customerId: data.customerId,
                pointToMoney: data.pointToMoney,
                companyId: data.companyId,
                companyName: data.companyName,
                isShowReturnBill: data.canReturnBill,
                canEditBill: true,
                transferAccName: data.transferAccName,
                creditAccName: data.creditAccName,
                cashAccName: data.cashAccName,
                installmentAccName: data.installmentAccName,
                titleCash: data.cashAccName
                  ? 'Tiền mặt: ' + data.cashAccName
                  : 'Tiền mặt',
                titleTransfer: data.transferAccName
                  ? 'Chuyển khoản: ' + data.transferAccName
                  : 'Chuyển khoản',
                titleCredit: data.creditAccName
                  ? 'Quẹt thẻ: ' + data.creditAccName
                  : 'Quẹt thẻ',
                titleInstallment: data.installmentAccName
                  ? 'Trả góp: ' + data.installmentAccName
                  : 'Trả góp',
                shippingFee: data.shippingFee,
                deviceType: data.deviceType,
                payments: data.payments,
              };
              this.bills.push(item);
              this.totalQuantity += element.quantity;
              if (show) {
                this.totalAmount += data.totalAmount;
                this.totalPayment += data.paymentAmount;
              }
            }
          });
          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          this.makeToastFaile(`Lỗi: ${err}`);
        });
    },
    fetchListBill: async function () {
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      this.onLoading = true;
      let listCriteria = [];
      if (this.value.length) {
        listCriteria = this.value.map(({ code }) => code);
      }
      this.filterdOptions = cloneDeep(this.options);
      if (this.searchEmployee.trim() === '') {
        this.selectedEmployee = null;
      }
      const param = {
        page: this.page,
        limit: 10,
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: this.billType,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        listCriteria: listCriteria,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
        searchCoupon: this.searchCoupon ? this.searchCoupon.trim() : '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('bills', paramQuery)
        .then((response) => {
          const { data } = response.data;
          this.bills = [];
          this.totalPages = data.totalPage;
          this.totalItems = data.totalRow;
          this.totalQuantity = 0;
          this.totalAmount = 0;
          this.totalPayment = 0;
          this.bills = data.dataset.reduce((result, item) => {
            let discountAmount = '';
            if (item.discountType === 2) {
              discountAmount = item.discountAmount + '%';
            } else if (item.discountType === 1) {
              discountAmount = this.convertPrice(item.discountAmount);
            }
            item.isShowReturnBill = item.canReturnBill;
            item.discountAmount = discountAmount;
            item.show = item.listProduct.length <= 0;
            item.showDetail = false;
            result.push(item);
            return result;
          }, []);
          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          this.makeToastFaile(`Lỗi: ${err}`);
        });
    },
    deleteItem: async function (item) {
      ApiService.delete('/bills/v2' + '/' + item.billNumber).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchListBill();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa hóa đơn!',
        text: 'Bạn có chắc muốn xóa hóa đơn này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('BILL_VIEW')) {
        count++;
      }
      if (localData.checkPermission('BILL_UPDATE')) {
        count++;
      }
      return count === 1;
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    linkToOrder: function (orderId) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: orderId },
      });
      window.open(href, '_blank');
    },
    debounceInput: decounce(() => {
      this.searchBill();
    }, TIME_TRIGGER),
    editUser: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerMobile },
      });
      window.open(href, '_blank');
    },
    hoverBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    fetchStoreByUser: async function () {
      await ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            const option = {
              code: element.id,
              name: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              const index = this.optionsStore.findIndex(
                (item) => item.code == element.id,
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filteredStore = cloneDeep(this.optionsStore);
        }
        if (this.$route.query.listStore) {
          this.valueStore = [];
          if (Array.isArray(this.$route.query.listStore)) {
            this.$route.query.listStore.forEach((id) => {
              const index = this.optionsStore.findIndex(
                (item) => item.code == id,
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
              this.filteredStore = cloneDeep(this.optionsStore);
            });
          } else {
            const index = this.optionsStore.findIndex(
              (item) => item.code == this.$route.query.listStore,
            );
            this.optionsStore[index].checked = true;
            this.valueStore.push(this.optionsStore[index]);
            this.filteredStore = cloneDeep(this.optionsStore);
          }
        }
      });
    },
    onFilter() {
      const isValidParam = this.validateParamSearch();
      if (!isValidParam) {
        return;
      }
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchListBill();
    },
    validateParamSearch() {
      const textSearchProduct = this.searchProductName
        ? this.searchProductName.trim()
        : '';
      if (textSearchProduct && textSearchProduct.length < 10) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm sản phẩm trên 10 ký tự!');
        return false;
      }
      const textSearchIMEI = this.searchIMEI ? this.searchIMEI.trim() : '';
      if (textSearchIMEI && textSearchIMEI.length < 5) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm IMEI trên 5 ký tự!');
        return false;
      }
      const textSearchCoupon = this.searchCoupon
        ? this.searchCoupon.trim()
        : '';
      if (textSearchCoupon && textSearchCoupon.length < 5) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm mã coupon trên 5 ký tự!');
        return false;
      }
      const textSearchNote = this.searchNote ? this.searchNote.trim() : '';
      if (textSearchNote && textSearchNote.length < 5) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm ghi chú trên 5 ký tự!');
        return false;
      }
      return true;
    },
    changeURL() {
      let queryObj = this.setFillter();
      const routerName = this.getByType('refresh');
      this.$router.push({
        name: routerName,
        query: queryObj,
      });
    },
    onSelectedEmployee(option) {
      this.selectedEmployee = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      this.debounceInputEmployee();
    },
    debounceInputEmployee: decounce(function () {
      this.getListEmployee();
    }, TIME_TRIGGER),
    getListEmployee() {
      this.optionsEmployee[0].data = [];
      this.filteredOptionsEmployee = [...this.optionsEmployee[0].data];
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      ApiService.get(
        `employees/get-by-store?employeeName=${this.searchEmployee}&storeId=${listStoreId}`,
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
        this.filteredOptionsEmployee = [...this.optionsEmployee[0].data];
      });
    },
    onSelect(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = true;
      this.filterdOptions = cloneDeep(this.options);
    },

    onRemove(option) {
      let index = this.options.findIndex((item) => item.name == option.name);
      this.options[index].checked = false;
      this.filterdOptions = cloneDeep(this.options);
    },
    customLabel(option) {
      return `${option.name}`;
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = true;
      this.filteredStore = cloneDeep(this.optionsStore);
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
      this.filteredStore = cloneDeep(this.optionsStore);
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    getLinkDoc(item) {
      return `#/bills/update-bill?id=${item.billNumber}`;
    },
    countBill: async function () {
      let result = 0;
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      this.onLoading = true;
      let listCriteria = [];
      if (this.value.length) {
        listCriteria = this.value.map(({ code }) => code);
      }
      this.filterdOptions = cloneDeep(this.options);
      let param = {
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: 1,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        saleId: this.selectedEmployee,
        listCriteria: listCriteria,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      await ApiService.query('bills/count', paramQuery).then(({ data }) => {
        result = data.data;
      });
      return result;
    },
    getFillter() {
      const validValues = getValuesEnum(BILL_TYPE);
      if (!validValues.includes(this.billType)) {
        throw new Error('Bill type không hợp lệ');
      }
      this.searchId = '';
      if (this.$route.query.id) {
        this.searchId = this.$route.query.id;
      }
      this.searchProductName = '';
      if (this.$route.query.searchProduct) {
        this.searchProductName = this.$route.query.searchProduct;
      }
      this.searchNameOrPhone = '';
      if (this.$route.query.searchCustomer) {
        this.searchNameOrPhone = this.$route.query.searchCustomer;
      }
      if (this.$route.query.dateFrom) {
        this.dateFrom = this.$route.query.dateFrom;
      }
      if (this.$route.query.dateTo) {
        this.dateTo = this.$route.query.dateTo;
      }
      if (this.$route.query.companyId) {
        this.selectedCompany = this.$route.query.companyId;
      }
      this.value = [];
      if (this.$route.query.listCriteria) {
        if (Array.isArray(this.$route.query.listCriteria)) {
          this.$route.query.listCriteria.forEach((id) => {
            let index = this.options.findIndex((item) => item.code == id);
            this.options[index].checked = true;
            this.value.push(this.options[index]);
          });
          this.filterdOptions = cloneDeep(this.options);
        } else {
          let index = this.options.findIndex(
            (item) => item.code == this.$route.query.listCriteria,
          );
          this.options[index].checked = true;
          this.value.push(this.options[index]);
          this.filterdOptions = cloneDeep(this.options);
        }
      }
      this.searchOrderId = '';
      if (this.$route.query.orderId) {
        this.searchOrderId = this.$route.query.orderId;
      }
      this.searchNote = '';
      if (this.$route.query.searchNote) {
        this.searchNote = this.$route.query.searchNote;
      }
      this.searchIMEI = '';
      if (this.$route.query.searchIMEI) {
        this.searchIMEI = this.$route.query.searchIMEI;
      }
      this.searchCoupon = '';
      if (this.$route.query.searchCoupon) {
        this.searchCoupon = this.$route.query.searchCoupon;
      }
    },
    setFillter() {
      let queryObj = {};
      if (this.searchId) {
        queryObj.id = this.searchId;
      }
      if (this.searchProductName) {
        queryObj.searchProduct = this.searchProductName;
      }
      if (this.searchNameOrPhone) {
        queryObj.searchCustomer = this.searchNameOrPhone;
      }
      if (this.dateFrom) {
        queryObj.dateFrom = this.dateFrom;
      }
      if (this.dateTo) {
        queryObj.dateTo = this.dateTo;
      }
      if (this.selectedCompany) {
        queryObj.companyId = this.selectedCompany;
      }
      let listCriteria = [];
      this.options.forEach((element) => {
        if (element.checked) {
          listCriteria.push(element.code);
        }
      });
      this.filterdOptions = cloneDeep(this.options);
      if (listCriteria.length > 0) {
        queryObj.listCriteria = listCriteria;
      }
      let listStoreId = [];
      this.optionsStore.forEach((element) => {
        if (element.checked) {
          listStoreId.push(element.code);
        }
      });
      if (listStoreId.length > 0) {
        queryObj.listStore = listStoreId;
      }
      if (this.searchOrderId && this.searchOrderId.trim() !== '') {
        queryObj.orderId = this.searchOrderId;
      }
      if (this.searchIMEI && this.searchIMEI.trim() !== '') {
        queryObj.searchIMEI = this.searchIMEI;
      }
      if (this.searchNote && this.searchNote.trim() !== '') {
        queryObj.searchNote = this.searchNote;
      }
      if (this.searchCoupon && this.searchCoupon.trim() !== '') {
        queryObj.searchCoupon = this.searchCoupon;
      }
      return queryObj;
    },
    createWarrantyBill(item) {
      this.$router.push({
        name: 'add-warranty-bill',
        query: { billId: item.billNumber, action: 'create' },
      });
    },
    showModalExcel() {
      this.mode = this.billType;
      this.apiParams = {
        fromDate: this.dateFrom,
        toDate: this.dateTo,
        productSearch: this.searchProductName,
        companyId: this.selectedCompany || 3,
        storeIds: this.valueStore,
        criteriaIds: this.value,
        searchEmployee: this.selectedEmployee,
        orderId: this.searchOrderId,
        searchCustomer: this.searchNameOrPhone,
        couponCode: this.searchCoupon,
        searchIMEI: this.searchIMEI,
        billId: this.searchId,
        note: this.searchNote,
      };
      this.$bvModal.show('modal-bill-excel');
    },
    rePostItem: function (item) {
      this.$router.push({
        name: 'add-bill',
        query: { id: item.billNumber },
      });
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Store':
          this.searchStore(textInput);
          break;
        case 'Characteristic':
          this.searchCharacteristic(textInput);
          break;
        default:
          break;
      }
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    searchCharacteristic(textInput) {
      let options = cloneDeep(this.options);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptions = map(options, (obj) => {
          return assign(obj, find(this.filterdOptions, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptions, { id: obj.id }));
      });

      this.filterdOptions = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    getByType(name, item) {
      switch (name) {
        case 'router': {
          const routerNames = [null, 'add-bill', 'add-wholesale-invoice'];
          return routerNames[this.billType];
        }
        case 'router-update': {
          const routerNames = [null, 'update-bill', 'update-wholesale-invoice'];
          return routerNames[this.billType];
        }
        case 'router-link-id': {
          const routerNames = [
            null,
            '#/bills/update-bill',
            '#/wholesaleInvoices/update-wholesale-invoice',
          ];
          return routerNames[this.billType] + `?id=${item.billNumber}`;
        }
        case 'title': {
          const title = [
            null,
            'Danh sách hóa đơn bán lẻ',
            'Danh sách hóa đơn bán buôn',
          ];
          return title[this.billType];
        }
        case 'resfresh': {
          return this.billType === BILL_TYPE.RETAIL_INVOICE
            ? 'list-bill'
            : this.billType === BILL_TYPE.WHOLESALE_INVOICE
            ? 'list-wholesale-invoice'
            : null;
        }
      }
    },
    showPopupVatInvoice(bill, mode) {
      const addVatInvoice = { bill, mode };
      EventBus.$emit('popup-create-vat-invoice', addVatInvoice);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    totalQuantity() {
      const flattenBillItems = flatten(
        this.bills.map((bill) => bill.listProduct),
      );
      return sumBy(flattenBillItems, 'quantity');
    },
    totalAmount() {
      return sumBy(this.bills, 'totalAmount');
    },
    totalPayment() {
      return sumBy(this.bills, 'paymentAmount');
    },
  },
};
</script>

<style scoped>
.orderCode:hover {
  text-decoration: underline;
}

.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.table th,
.table td {
  vertical-align: middle;
}

.tdTextAlign {
  vertical-align: middle;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
}

.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}

.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}

.table-bordered-list td {
  border: 0.5px solid #f3f3f3;
  /* border: 0.5px solid #fdfbfb; */
  /* border: 0.5px solid #d5d5d5; */
  border-bottom: 0.5px solid #adadad;
}

.text-weight {
  font-weight: 550;
}

.font-size-gift {
  font-size: 11px;
}

.font-size-attach {
  font-size: 11px;
}

.tdCode {
  width: 5%;
}

.tdCustomer {
  width: 7%;
}

.tdProductName {
  width: 25%;
}

.tdProductPrice {
  width: 8%;
}

.tdQuantity {
  width: 3%;
}

.tdDiscountItem {
  width: 5%;
}

.tdTotalAmount {
  width: 8%;
}

.tdTotalPayment {
  width: 12%;
  font-weight: 700;
}

.tdNote {
  width: 19%;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}

.search-col {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.bill-page {
  .cursor-pointer {
    cursor: pointer;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }

  input.form-control {
    border: 1px solid #ced4da;
  }

  .dashed {
    border: 1px dashed #464e5f;
    border-style: none none dashed;
    color: #fff;
    background-color: #fff;
  }

  .filter-btn {
    font-weight: 600;
    width: 70px;
  }

  .function-btn {
    font-size: 1rem;
    padding-right: 0;
  }

  .edit-note-btn {
    font-weight: 600;
    width: 70px;
  }
}
</style>
